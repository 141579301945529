<template>
  <div class="credit-card">
    <h2 class="credit-card__title">Name on Card</h2>
    <div class="credit-card__row">
      <input
        v-model="cardData.name"
        type="text"
        name="name"
        id="name"
        placeholder="Name on Card"
        :class="{'is-invalid': $v.cardData.name.$dirty && !$v.cardData.name.required }"
      />
    </div>
    <h2 class="credit-card__title">Credit Card</h2>
    <div class="credit-card__row credit-card__row-two-col ">
      <div class="credit-card__number-row">
        <input
          class="credit-card__number"
          v-model="cardData.number"
          type="text"
          autocomplete="cc-number"
          placeholder="Card Number"
          v-mask="['#### #### #### ####','#### #### #### ###']"
          :masked="true"
          :class="{'is-invalid': $v.cardData.number.$dirty && (!$v.cardData.number.required || !$v.cardData.number.number)}"
        />
      </div>
      <div class="credit-card__row-right-col">

        <input
          v-model="cardData.expMonth"
          class="credit-card__month"
          type="text"
          maxlength="2"
          placeholder="MM"
          autocomplete="cc-exp-month"
          :class="{'is-invalid': $v.cardData.expMonth.$dirty &&
                      (!$v.cardData.expMonth.required || !$v.cardData.expMonth.between || !$v.cardData.expMonth.numeric) }"
        />
        <span>/</span>
        <input
          v-model="cardData.expYear"
          class="credit-card__year"
          type="text"
          maxlength="2"
          placeholder="YY"
          autocomplete="cc-exp-year"
          :class="{'is-invalid': $v.cardData.expYear.$dirty &&
                      (!$v.cardData.expYear.required || !$v.cardData.expYear.minValue || !$v.cardData.expYear.numeric)}"
        />
        <input
          v-model="cardData.cvc"
          class="credit-card__cvc"
          type="text"
          placeholder="CVC"
          v-mask="['###', '####']"
          :class="{'is-invalid': $v.cardData.cvc.$dirty &&
                  (!$v.cardData.cvc.required || !$v.cardData.cvc.minLength || !$v.cardData.cvc.maxLength || !$v.cardData.cvc.numeric || !$v.cardData.cvc.cvc) }"
        />
      </div>

    </div>
    <div v-if="$v.$invalid" class="form__error credit-card__error">
      <div v-if="$v.cardData.name.$dirty && !$v.cardData.name.required">
        The Name is required.
      </div>
      <div v-if="$v.cardData.number.$dirty && (!$v.cardData.number.required || !$v.cardData.number.number)">
        The card number is invalid.
      </div>
      <div v-if="$v.cardData.expMonth.$dirty && (!$v.cardData.expMonth.required || !$v.cardData.expMonth.between || !$v.cardData.expMonth.numeric)">
        The month is invalid.
      </div>
      <div v-if="$v.cardData.expYear.$dirty && (!$v.cardData.expYear.required || !$v.cardData.expYear.minValue || !$v.cardData.expYear.numeric)">
        The year is invalid.
      </div>
      <div v-if="$v.cardData.cvc.$dirty && (!$v.cardData.cvc.required || !$v.cardData.cvc.minLength || !$v.cardData.cvc.maxLength || !$v.cardData.cvc.numeric || !$v.cardData.cvc.cvc)">
        The CVC code is invalid.
      </div>
    </div>
  </div>

</template>

<script>
  import { required, minLength, maxLength, minValue, between, numeric } from 'vuelidate/lib/validators'
  import { isSecurityCodeValid, isValid } from "creditcard.js";

  export default {
    data() {
      return {
        cardData: {
          name: null,
          number: '',
          expMonth: null,
          expYear: null,
          cvc: null
        },
      };
    },
    validations: {
      cardData: {
        name: { required },
        number: {
          required,
          number: function(){ return isValid( this.cardData.number ) }
        },
        expMonth: {
          required,
          between: between(1, 12),
          numeric
        },
        expYear: {
          required,
          minValue: minValue(22),
          numeric
        },
        cvc: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(4),
          numeric,
          cvc: function(){ return isSecurityCodeValid( this.cardData.number, this.cardData.cvc ) }
        }
      },
    },
  };
</script>

<style lang="scss">
.is-invalid{
  color: #f00;
}
</style>
