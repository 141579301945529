<template>
  <div class="payment__plan">
    <div class="form__row">
      <label class="center nice-checkbox">
        <input
          v-model="option.payment_plan_activated"
          type="checkbox"
          :disabled="isDisabledField"
          :class="{
            'disabled': isDisabledField
          }"
          @change="activatePaymentPlan"
        />
        <span>I Would like to make the Payment in monthly installments</span>
      </label>
    </div>
    <div class="row" v-if="isActivePaymentPlan">
      <div class="col-6">
        <v-select
            class="select"
            placeholder="Amount of Payments"
            :options="paymentMonthCountOptions"
            label="amount"
            :searchable="false"
            v-model="monthSelected"
            :disabled="isDisabledField"
            @input="selectPaymentMonth"
        />
      </div>
      <div class="col-6">
        <h4 class="payment__plan-monthly">Monthly payment: ${{monthlyPayment}}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
  },
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    isDisabledField: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isActivePaymentPlan: false,
      monthSelected: 0,
    }
  },
  mounted() {
    this.activatePaymentPlan()
    this.monthSelected = this.option.payment_month_selected
  },
  computed: {
    paymentMonthCountOptions() {
      const maxMonths = this.option.max_months
      if (maxMonths) {
        return Array.from({length: maxMonths}, (_, index) => index + 1)
      }
      return []
    },
    monthlyPayment() {
      if (this.monthSelected){
        return (Number(this.option.charge_amount)/Number(this.monthSelected)).toFixed(2)
      }
      return Number(this.option.charge_amount).toFixed(2);
    }
  },
  methods: {
    activatePaymentPlan() {
      this.isActivePaymentPlan = this.option.payment_plan_activated
    },
    selectPaymentMonth() {
      this.option.payment_month_selected = this.monthSelected
    },
  },
}
</script>

<style lang="scss" scoped>
.payment__plan{
  margin-bottom: 30px;

  .row {
    align-items: center;
  }

  &-monthly{
    font-size: 16px;
    line-height: 20px;
    text-align: right;
  }
}
.select {
  margin-bottom: 0;
}
</style>
