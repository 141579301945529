var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-type-row"},[_c('p',{staticClass:"group-type-label"},[_vm._v(_vm._s(_vm.groupData.label)+" "),(_vm.groupData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('v-select',{class:{
      'multiple-select': _vm.isMultiple,
      'select': !_vm.isMultiple,
      'is-invalid': _vm.isInvalid
    },attrs:{"placeholder":"Select","disabled":_vm.isDisabledField,"multiple":_vm.isMultiple,"deselectFromDropdown":_vm.isMultiple,"closeOnSelect":!_vm.isMultiple,"searchable":false,"options":_vm.preparedOptions,"selectable":function (option) { return _vm.isSelectable(option); },"label":"name"},on:{"input":_vm.emitChanges},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" There is no options for "+_vm._s(_vm.groupData.label)+" ")]},proxy:true},{key:"option",fn:function(ref){
    var id = ref.id;
    var name = ref.name;
    var price = ref.price;
    var max_capacity = ref.max_capacity;
    var member_count = ref.member_count;
    var is_allow_viewing_other_members = ref.is_allow_viewing_other_members;
    var is_capacity_enforcement = ref.is_capacity_enforcement;
return [_c('span',[_vm._v(_vm._s(name)+" "),(is_capacity_enforcement && max_capacity && max_capacity <= member_count)?_c('i',[_vm._v("(full)")]):_vm._e()]),(is_allow_viewing_other_members)?[_c('span',{staticStyle:{"textDecoration":"underline"},on:{"click":function($event){$event.stopPropagation();return _vm.showGroupUsersList(id)}}},[_vm._v(" See who is in this group ")])]:_vm._e(),(price === 0)?_c('span'):(!_vm.hidePrice && !_vm.groupData.hide_price)?[_c('span',[(price > 0)?_c('b',[_vm._v("+")]):_vm._e(),(price < 0)?_c('b',[_vm._v("-")]):_vm._e(),_vm._v("$"+_vm._s(_vm._f("toFixedNumber")(price))+" ")])]:_vm._e()]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }