<template>
  <div>
    <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>

    <div
      class="signature-container"
      ref="container"
    >
      <vue-drawing-canvas
        class="signature"
        :ref="refName"
        :canvas-id="refName"
        height="100"
        :width="width"
        line-cap = 'round'
        line-join = 'round'
        :class="{'disabled': isDisabledField}"
        :disabled="isDisabledField"
        :background-image="backgroundImage"
      />

      <button
        v-if="!isDisabledField"
        class="btn btn--color-outline-inactive signature-clear-btn"
        @click="clear()"
      >X</button>
    </div>
  </div>
  
</template>

<script>
import VueDrawingCanvas from 'vue-drawing-canvas'
export default {
  components: {
    VueDrawingCanvas,
  },
  props: {
    fieldsData: {
      type: Object,
      default: () => {},
    },
    isDisabledField: {
      type: Boolean,
      default: false
    },
    refName: {
      type: String,
      required: true
    },
  },
  data: () => ({
    width: 450,
    backgroundImage: null,
  }),
  mounted() {
    if (this.fieldsData.value) {
      this.fromDataURL(this.fieldsData.value)
    }
  },
  methods:{
    clear(){
      this.backgroundImage = null
      this.$refs[this.refName].reset()
    },
    fromDataURL(url){
      this.backgroundImage = url
    },
  }
}
</script>

