<template>
  <label class="input-file">
    <div class="input-file-field">
      <span v-if="value.length > 0">Selected: {{label}}</span>
      <span v-else :class="{ 'is-invalid': maxSizeError }">Upload File (max size 20Mb)</span>
      <span class="input-file-field-button">Upload</span>
    </div>
    <input
      type="file"
      multiple
      :disabled="disabled"
      @change="handleFileChange"
    />
    
    <p class="form__error">{{errorMessage}}</p>
  </label>
</template>

<script>
import useApplicationAdd from '@/views/Application/useApplicationAdd'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    fieldsData: {
      type: Object,
      default: () => {},
    },
    stepStatus: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    maxSizeError: false,
    errorMessage: '',
    value: []
  }),
  setup() {
    const {
      uploadFiles,
    } = useApplicationAdd()

    return {
      uploadFiles,
    }
  },
  mounted() {
    this.value = this.fieldsData.value
  },
  computed: {
    label() {
      return `${this.value.length} files`
    }
  },
  methods: {
    uploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      return this.uploadFiles(`application-files-${new Date().getTime()}`, formData)
    },
    handleFileChange(e) {
      const files = e.target.files
      const maxSize = 20
      for (let i = 0; i < files.length; i++) {
        const filesize = ((files[i].size/1024)/1024).toFixed(4)
        if(filesize > maxSize) {
          this.maxSizeError = true
          this.$emit('input', null)
          return
        }
      }
      
      if(this.stepStatus == 'Draft') {
        this.$emit('loading', true)
        const filesLinks = []
        const uploadedFiles = []

        Array.from(files).forEach(val => {
          filesLinks.push(this.uploadFile(val))
        })

        Promise.all(filesLinks)
          .then(responses => responses.forEach(
            response => {
              if (!response.file_path) {
                this.$emit('loading', false)
                this.errorMessage = 'Error uploading file'
                throw new Error('Error')
              }
              uploadedFiles.push(response)
            },
          ))
          .then(() => {
            this.$emit('loading', false)
            this.$emit('addFiles', uploadedFiles)
          })
      } else {
        this.$emit('input', files)
        this.$emit('addFiles', files)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-file {
    display: block;

    &-name{
      overflow: hidden;
    }
  }
</style>
