<template>
  <div class="group-type-row">
    <p class="group-type-label">{{ groupData.label }} <span v-if="groupData.required">*</span> </p>
    <v-select
      :class="{
        'multiple-select': isMultiple,
        'select': !isMultiple,
        'is-invalid': isInvalid
      }"
      placeholder="Select"
      :disabled="isDisabledField"
      :multiple="isMultiple"
      :deselectFromDropdown="isMultiple"
      :closeOnSelect="!isMultiple"
      :searchable="false"
      :options="preparedOptions"
      :selectable="option => isSelectable(option)"
      label="name"
      v-model="value"
      @input="emitChanges"
    >
      <template #no-options>
        There is no options for {{ groupData.label }}
      </template>

      <template
        #option="{ id, name, price, max_capacity, member_count, is_allow_viewing_other_members, is_capacity_enforcement }"
        :class="price"
      >
        <span>{{name}} <i v-if="is_capacity_enforcement && max_capacity && max_capacity <= member_count">(full)</i> </span> 

        <template v-if="is_allow_viewing_other_members">
          <span
            style="textDecoration: underline;"
            @click.stop="showGroupUsersList(id)"
          >
            See who is in this group
          </span>
        </template>
        
        <span v-if="price === 0"></span>
        <template v-else-if="!hidePrice && !groupData.hide_price">
          <span>
            <b v-if="price > 0">+</b><b v-if="price < 0">-</b>${{price | toFixedNumber}}
          </span>
        </template>

      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import store from "@/store";

export default {
  components: {
    vSelect,
  },
  props: {
    groupData: {
      type: Object,
      default: () => {},
    },
    groupValue: {
      type: null,
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    isDisabledField: {
      type: Boolean,
      default: false
    },
  },
  filters: {
    toFixedNumber(value) {
      return value.toFixed(2).replace('-','')
    }
  },
  data() {
    return {
      value: null,
      options: [],
    }
  },
  watch: {
    async chosenSessions() {
      await this.prepareGroupOptions()
    },
    async applicationId(val, prevVal) {
      if(val != prevVal) await this.prepareGroupOptions()
    },
    groupValue(val) {
        if (val == undefined) {
          this.value = null
        } else {
          this.value = val
        }
    },
  },
  async mounted() {
    await this.prepareGroupOptions()
    this.options = this.groupData.options
    if (this.groupData.value) {
      const availableGroupIds = this.options.map(group => group.id)
      this.value = this.groupData.value.filter(session => availableGroupIds.includes(session.id))
    } else {
      this.value = null
    }
  },
  computed: {
    ...mapGetters({
      hidePrice: 'applicationRegistration/getHidePrice',
      chosenSessions: 'applicationRegistration/getChosenSessions',
      programId: 'applicationRegistration/getProgramID',
      applicationId: 'applicationRegistration/getApplicationId',
    }),
    preparedOptions() {
      return this.options.reduce((options, group) => {
        if (group.is_visible) {
          group.price = group.sessions.reduce((totalPrice, session) => {
            totalPrice += session.pivot_price
            return totalPrice
          }, 0)
          options.push(group)
          return options
        } else {
          return options
        }
      },[])
    },
    isMultiple() {
      return true
    },
  },
  methods: {
    isCompatibleForSelect(newOption){
      if (this.value == null) {
        return true
      }

      if (this.value.length === 0) {
        return true
      }

      if (this.value[0].id == newOption.id) {
        return true
      }

      return !this.value[0].is_restrict_single_group_type && !newOption.is_restrict_single_group_type
    },
    isSelectable(option) {
      return option.is_allow_use_selection && this.isNotFullGroup(option) && this.isCompatibleForSelect(option)
    },
    isNotFullGroup(option) {
      if (!option.max_capacity) {
        return true
      }
      return !option.is_capacity_enforcement || (option.is_capacity_enforcement && option.max_capacity > option.member_count)
    },
    emitChanges() {
      this.groupData.value = this.value

      let result
      if (Array.isArray(this.groupData.value)) {
        result = this.groupData.value.map(a => a.name)
      } else {
        result = this.groupData.value?.name
      }

      this.$store.commit('applicationRegistration/UPDATE_ALL_SELECTED_GROUPS', this.groupData.value)
      this.$emit('change',  result)
    },
    async prepareGroupOptions() {
      const queryParams = {}
      queryParams.program_id = this.programId
      queryParams.session_id = this.chosenSessions.map(session => session.id)
      queryParams.grade = this.gradeValue
      queryParams.group_type = this.groupData.default
      queryParams.application_id = this.applicationId

      await store.dispatch('applicationRegistration/fetchApplicationGroupOptions', queryParams)
        .then(res => {
          this.options = res
          this.groupData.options = res
        })
    },
    showGroupUsersList(groupId) {
      store.commit('applicationRegistration/SET_SELECTED_GROUP_FOR_VIEWING_USERS', groupId)
      this.$bvModal.show('group-users-list')
    }
  }
}
</script>

<style lang="scss">
.group-type-label{
  margin-bottom: 10px;
}
.is-invalid .vs__dropdown-toggle {
  border: 1px solid red;
}
</style>