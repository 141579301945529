<template>
  <div v-if="option">
    <div class="payment-wizard-option">
      <h3 class="payment-wizard-option__title">{{ option.name }}</h3>
      <div class="payment-wizard-option__amount">
        <span>Charge amount ($)</span>
        <span>{{ option.charge_amount }}</span>
      </div>
      <div class="payment-wizard-option__description">
        <span>{{ option.description }}</span>
      </div>
    </div>
    
    <PaymentPlan 
       v-if="option.payment_plan_is_enable"
       :option="option"
       :isDisabledField="isDisabledField"
    />

  </div>
  
</template>

<script>
import PaymentPlan from '@/components/site/PaymentPlan.vue'

export default {
  components: {
    PaymentPlan,
  },
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    isDisabledField: {
      type: Boolean,
      default: false
    },
  },
}
</script>

